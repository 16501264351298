import React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import avatar from "../static/images/avatar2.jpg"

const AboutPage = () => (
    <Layout>
        <SEO title="Über mich"
             description="Erik arbeitet beruflich an der Schnittstelle zwischen Kunde und Entwicklungsteams und teilt hier seine Erfahrungen, um Ideen ganzheitlich umzusetzen."/>
        <h1 className="About-title">Über mich</h1>
        <h2 className="About-subtitle">Jahrgang 69 und begeisterter Einrad-Fahrer.</h2>
        <div className="About-grid">
            <div className="About-grid-image">
                <img src={avatar} alt="Erik" className="About-avatar lazyload"/>
            </div>
            <div className="About-grid-text">
                <p>
                    Sowohl beim Einrad-Hockey als auch alleine beim Cross-Country-Fahren auf dem Einrad kommt es auf <strong>Flexibilität und Agilität</strong> an. </p>
                <p>Beruflich arbeite ich meist an der ebenso fordernden wie
                    faszinierenden <strong>Schnittstelle zwischen Kunde und Entwicklungsteams</strong> – genau der richtige Ort für meine Leidenschaft
                    für gute Lösungen und Produkte. </p>
            </div>
            <div className="About-grid-text">
                <p>
                    Da sind oft <strong>tolle Fragestellungen und ebenso viele gute Ideen</strong> für ihre Beantwortung unterwegs. Doch
                    den
                    Teams fehlen mitunter schlichtweg das Handwerkszeug und die Struktur, mit einem <strong>ganzheitlichen, holistischen Blick</strong> (daher der Name meiner Webseite) aus all diesen Ideen <strong>gute Lösungen</strong> machen zu können – mit denen
                    nicht nur sie selbst glücklich sind, sondern auch ihre Auftraggeber.
                </p>
            </div>
        </div>
        <div style={{margin: '3em 0 4em 0'}}>
            <Link to="/" className="button orange"> Zurück zur Startseite </Link>
        </div>
    </Layout>
);

export default AboutPage
